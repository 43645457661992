<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newVehicle') }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="vehicles" :actions="tableActions" :loading="loading" :filterAttributes="['Active', 'Inactive']"
        @filterChanged="fetchFilteredItems" :filterLabel="'Status'" :filter1="true"/>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
        { title: 'Vehicle Code', key: 'vehicle_code', align: 'start', clickable: true, sortable: true },  
        { title: 'Vehicle Name', key: 'vehicle_name', align: 'start', sortable: true},
        { title: 'Load Ration (in CRT)', key: 'loading_ratio_in_crt', align: 'start', sortable: true},
        { title: 'Cubic Vehicle', key: 'volume', align: 'start', sortable: true},
        { title: 'Load Maximum', key: 'weight', align: 'start', sortable: true},
        { title: 'Status', key: 'status', align: 'start'},
        { title: '', key: 'actions', align: 'start'},
        ],
        vehicles: [],
        tableActions: {
          edit: this.navigateToForm,
          delete: this.deleteVehicle,
          view: this.navigateToForm
        },
        loading: false
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'vehicle-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchVehicle() {
        this.loading = true;
        try {
          const response = await axios.get('/vehicle');
          this.vehicles = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages 
                              ? error.response.data.messages 
                              : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, vehicle = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'vehicle-view', params: { action, id: vehicle.id } });
        } else if(action == 'import') {
            this.$router.push({ name: 'vehicle-import', params: { action, id: vehicle.id } });
        } else {
          this.$router.push({ name: 'vehicle-form', params: { action, id: vehicle.id } });
        }
      },
      async deleteVehicle(vehicleToDelete) {
        try {
          const response = await axios.delete(`/vehicle/${vehicleToDelete.id}`);
  
          if (response.data) {
            this.vehicles = this.vehicles.filter(u => u.id !== vehicleToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete vehicle');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages 
                              ? error.response.data.messages 
                              : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchFilteredItems(filter) {
        this.loading = true;
        if (filter.attribute) {
          try {
            const response = await axios.get(`/vehicle?status=${filter.attribute}`);
            this.vehicles = response.data;
            this.loading = false;
          } catch (error) {
            this.loading = false;
            const errorMessage = error.response?.data?.messages 
                                ? error.response.data.messages 
                                : error.response?.message || 'An error occurred';
    
            this.showAlert({ message: errorMessage, color: 'error' });
          }
        } else {
          this.fetchVehicle();
        }
        this.loading = false;
      }
    },
    mounted() {
      this.fetchVehicle();
    }
  };
  </script>
  