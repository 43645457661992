<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <!-- <v-btn disabled color="primary" @click="navigateToForm('add')">{{ $t('button.newSalesTarget') }}</v-btn> -->
        <v-btn class="ml-2" color="primary" @click="navigateToForm('import')">Import</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="salesTarget" :actions="tableActions" :loading="loading" :filterAttributes="Array.from({ length: 12 }, (_, i) => i + 1)"
        @filterChanged="fetchFilteredItems" :filterLabel="'Month'" :filter1="true"/>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
          { title: 'Total Qty', key: 'total_qty', align: 'start', clickable: true, sortable: true },
          { title: 'Product Description', key: 'product_description', align: 'start', sortable: true },
          { title: 'Distributor', key: 'distributor_name', align: 'start', sortable: true },
          { title: 'Month', key: 'month', align: 'start', sortable: true },
          { title: 'Year', key: 'year', align: 'start', sortable: true },
          { title: '', key: 'actions', align: 'end', sortable: false },
        ],
        salesTarget: [],
        tableActions: {
          // edit: this.navigateToForm,
          delete: this.deleteProductDivision,
          view: this.navigateToForm
        },
        loading: false,
        distributor: [],
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'salesTarget-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchSalesTarget() {
        this.loading = true;
        try {
          const response = await axios.get('/sales_target');
          this.salesTarget = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, salesTarget = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'salesTarget-view', params: { action, id: salesTarget.id } });
        } else if(action == 'import') {
            this.$router.push({ name: 'salesTarget-import', params: { action, id: salesTarget.id } });
        }  else {
          this.$router.push({ name: 'salesTarget-form', params: { action, id: salesTarget.id } });
        }
      },
      async deleteProductDivision(salesTargetToDelete) {
        try {
          const response = await axios.delete(`/sales_target/${salesTargetToDelete.id}`);
  
          if (response.data) {
            this.salesTarget = this.salesTarget.filter(u => u.id !== salesTargetToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete salesTarget');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchDistributor() {
        this.loading = true;
        try {
          const response = await axios.get('/distributor');
          this.distributor = response.data.map(distributor => distributor.name);
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';

          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      async fetchFilteredItems(filter) {
        this.loading = true;
        if (filter.attribute) {
          try {
            const response = await axios.get(`/sales_target?month=${filter.attribute}`);
            this.salesTarget = response.data;
            this.loading = false;
          } catch (error) {
            this.loading = false;
            const errorMessage = error.response?.data?.messages 
                                ? error.response.data.messages 
                                : error.response?.message || 'An error occurred';
    
            this.showAlert({ message: errorMessage, color: 'error' });
          }
        } else {
          this.fetchSalesTarget();
        }
        this.loading = false;
      }
    },
    mounted() {
      this.fetchSalesTarget();
      this.fetchDistributor();
    }
  };
  </script>