<template>
    <div>
        <v-row>
            <v-col cols="4">
              <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Search all attributes" variant="outlined" bg-color="white" clearable></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-select v-if="filter1"
                  v-model="selectedFilter" :items="filterAttributes" :label="filterLabel" bg-color="white" variant="outlined" clearable
                  @update:modelValue="onUpdateModelValue"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-data-table :headers="headers" :items="items" item-key="id" class="elevation-1" :loading="loading" :mobile="mobile"  
                :v-model="selected" :show-select='showSelect' :search="search"> 
                  <template v-for="header in headers" v-slot:[`item.${header.key}`]="{ item }">
                      <!-- Custom View for Status Column -->
                      <template v-if="header.key === 'status'">
                          <div v-if="item.status !== null">
                              <v-chip :color="getStatusColor(item.status)" variant="flat" size="x-small">
                                  {{ item.status }}
                              </v-chip>
                          </div>
                          <div v-else></div>
                      </template>
                      <template v-else-if="header.key === 'actions'">
                          <v-btn v-if="actions.edit" density="compact" variant="text" color="primary"
                              @click="() => handleAction('edit', item)">{{ $t('actions.edit') }}</v-btn>
                          <v-btn v-if="actions.delete" density="compact" variant="text" color="error"
                              @click="confirmDelete(item)">{{ $t('actions.delete') }}</v-btn>
                      </template>
                      
                      <template v-else-if="header.clickable">
                          <v-btn variant='text' @click="handleAction('view', item)" class="pa-0 text-start align-start" density="compact">
                              {{ item[header.key] }}
                          </v-btn>
                      </template>
                      <template v-else>
                          {{ item[header.key] }}
                      </template>
                    </template>
                    <template v-slot:loading>
                        <v-skeleton-loader type="table-row@5"></v-skeleton-loader>
                    </template>
              </v-data-table>
            </v-col>
        </v-row>
       

        <!-- Confirmation Dialog -->
        <v-dialog v-model="showConfirmation" max-width="400" persistent>
            <v-card>
                <v-card-title>
                    Confirm Deletion
                </v-card-title>
                <v-card-subtitle>
                    Are you sure you want to delete this item?
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="showConfirmation = false">
                        Cancel
                    </v-btn>
                    <v-btn @click="deleteItem">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        headers: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        actions: {
            type: Object,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            default: false,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Array,
            default: []
        },
        showSelect: {
            type: Boolean,
            default: false
        },
        filterAttributes: {
          type: Array,
          default: () => [] // list of attributes to filter by, passed from the parent
        },
        filterLabel: {
            type: String,
            default: ''
        },
        filter1: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showConfirmation: false,
            itemToDelete: null,
            search: null,
            selectedFilter: null,
        };
    },
    methods: {
        handleAction(actionName, item) {
            if (this.actions[actionName]) {
                if (actionName == 'edit') {
                    this.actions[actionName]('edit', item)
                } else if (actionName == 'view') {
                    this.actions[actionName]('view', item)
                } else {
                    this.actions[actionName](item);
                }
            }
        },
        confirmDelete(item) {
            this.itemToDelete = item;
            this.showConfirmation = true;
        },
        deleteItem() {
            if (this.actions.delete && this.itemToDelete) {
                this.actions.delete(this.itemToDelete);
                this.itemToDelete = null;
                this.showConfirmation = false;
            }
        },
        navigateToView(item) {
            this.$router.push({ name: 'home', params: { id: item.id } });
        },
        getStatusColor(status) {
          if(status !== null){
                  switch (status.toLowerCase()) {
                  case 'active':
                      return 'green';
                  case 'inactive':
                      return 'red';
                  case 'draft':
                      return 'yellow';
                  case 'pending':
                      return 'orange';
                  case 'request for revise':
                      return 'orange';
                  case 'awaiting for approval':
                      return 'primary';
                  case 'approved':
                      return 'green';
                  default:
                      return 'primary';
              }
          } else {
              return null;
          }
        },
        onUpdateModelValue(newValue) {
          if (newValue === null) {
            console.log('Cleared the selection');
            this.$emit("filterChanged", { attribute: null });
          } else {
            this.applyFilter(newValue);
          }
        },
        applyFilter() {
          if (this.selectedFilter) {
            // Make sure there's a method in the parent component to handle the API request
            // this.$emit("filterChanged", { attribute: this.selectedFilter, searchTerm: this.search });
            this.$emit("filterChanged", { attribute: this.selectedFilter });
          }
        },
    }
}
</script>

<style scoped>
.text-start {
  justify-content: flex-start;
}
</style>