<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newProduct') }}</v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <data-table :headers="headers" :items="product" :actions="tableActions" :loading="loading" :filterAttributes="['Active', 'Inactive']"
      @filterChanged="fetchFilteredItems" :filterLabel="'Status'" :filter1="true"/>
    </v-col>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
      { title: 'Product Code', key: 'sku', align: 'start', sortable: true},  
      { title: 'Product Description', key: 'description', align: 'start', clickable: true, sortable: true },
      { title: 'Product Nickname', key: 'product_name', align: 'start', clickable: true, sortable: true },
      { title: 'Product Division', key: 'product_division', align: 'start', clickable: true, sortable: true },
      { title: 'Product Category 1', key: 'product_category', align: 'start', clickable: true , sortable: true},
      { title: 'Product Category 2', key: 'product_category_2', align: 'start', clickable: true , sortable: true},
      { title: 'Status', key: 'status', align: 'start', sortable: true},
      { title: '', key: 'actions', align: 'end', sortable: false },
    ],
    product: [],
    tableActions: {
      edit: this.navigateToForm,
      delete: this.deleteProduct,
      view: this.navigateToForm
    },
    loading: false
  };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'product-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchProduct() {
      this.loading = true;
      try {
        const response = await axios.get('/product');
        this.product = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    navigateToForm(action, product = { id: null }) {
      if (action == 'view') {
        this.$router.push({ name: 'product-view', params: { action, id: product.id } });
      } else {
        this.$router.push({ name: 'product-form', params: { action, id: product.id } });
      }
    },
    async deleteProduct(productToDelete) {
      try {
        const response = await axios.delete(`/product/${productToDelete.id}`);

        if (response.data) {
          this.product = this.product.filter(u => u.id !== productToDelete.id);

          this.showAlert({ message: 'Success', color: 'success' });
        } else {
          throw new Error('Failed to delete product');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchFilteredItems(filter) {
      this.loading = true;
      if (filter.attribute) {
        try {
          const response = await axios.get(`/product?status=${filter.attribute}`);
          this.product = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages 
                              ? error.response.data.messages 
                              : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      } else {
        this.fetchProduct();
      }
      this.loading = false;
    }
  },
  mounted() {
    this.fetchProduct();
  }
};
</script>