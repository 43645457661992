<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Name</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model="productGroup.name" required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Gross Weight</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model.number="productGroup.gross_weight" required></v-text-field>
              <!-- <vue-number v-model="productGroup.loading_capacity" v-bind="number" class="custom-input text-right ma-2"></vue-number> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Loading Capacity</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model.number="productGroup.loading_capacity" required></v-text-field>
              <!-- <vue-number v-model="productGroup.loading_capacity" v-bind="number" class="custom-input text-right ma-2"></vue-number> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Ratio Loading Capacity</label>
            </v-col>
            <v-col md="3" sm="3" cols="12">
              <v-text-field v-model.number="productGroup.ratio_loading_capacity" required></v-text-field>
              <!-- <vue-number v-model="productGroup.loading_capacity" v-bind="number" class="custom-input text-right ma-2"></vue-number> -->
            </v-col>
          </v-row>
          <v-row>
                <v-col lg="2" md="2" sm="2" cols="12">
                  <label class="custom-label">Status</label>
                </v-col>
                <v-col md="3" sm="3" cols="12" class="py-2">
                  <v-radio-group
                    v-model="productGroup.status"
                    inline
                    hide-details
                  >
                    <v-radio
                      label="Active"
                      value="Active"
                    ></v-radio>
                    <v-radio
                      label="Inactive"
                      value="Inactive"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ isAdding ? $t('button.save') : $t('button.update') }}
      </v-btn>
    </template>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      productGroup: {
        id: null,
      }
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    const { action, id } = this.$route.params;

    // Fetch productGroup details if editing
    if (action === 'edit' && id) {
      try {
        const response = await axios.get(`/product_group/${id}`);
        this.productGroup = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const productGroupData = {
          name: this.productGroup.name,
          gross_weight: this.productGroup.gross_weight,
          loading_capacity: this.productGroup.loading_capacity,
          ratio_loading_capacity: this.productGroup.ratio_loading_capacity,
          status: this.productGroup.status
        };

        if (this.isAdding) {
          const response = await axios.post('/product_group/', { product_group: productGroupData });

          if (response.data) {
            this.$router.push({ name: 'productGroup' });
            this.showAlert({ message: 'Successfully added Product Group data', color: 'success' });
          } else {
            this.showAlert({ message: "Failed", color: 'error' });
          }
        } else {
          const response = await axios.put(`/product_group/${this.productGroup.id}`, { product_group: productGroupData });

          if (response.data) {
            this.$router.push({ name: 'productGroup' });
            this.showAlert({ message: 'Successfully added Product Group data', color: 'success' });
          } else {
            this.showAlert({ message: 'Error updating Product Group', color: 'error' });
          }
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    }
  }
};
</script>

<style scoped>
.custom-input {
	border: 1px solid #ddd;
	padding: 6px 6px;
	border-radius: 2px;
	width: 100%;
	box-sizing: border-box;
	text-align: right;
}
</style>