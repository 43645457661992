<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-row>
    <v-col cols="12" class="d-flex justify-end">
      <v-btn color="primary" @click="navigateToForm('add')">{{ $t('button.newUser') }}</v-btn>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <!-- <data-table :headers="headers" :items="users" :actions="tableActions" :loading="loading"/> -->
      <data-table :headers="headers" :items="users" :actions="tableActions" :loading="loading" :filterAttributes="['Active', 'Inactive']"
      @filterChanged="fetchFilteredItems" :filterLabel="'Status'" :filter1="true"/>
    </v-col>
  </v-row>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig';
import { mapActions } from 'vuex';
import DataTable from '@/components/DataTable.vue';

export default {
  components: {
    Breadcrumbs,
    DataTable
  },
  data() {
    return {
      headers: [
        { title: 'Username', key: 'username', align: 'start', clickable: true, sortable: true },
        { title: 'Email', key: 'email', align: 'start', clickable: true, sortable: true },
        { title: 'Employee Name', key: 'full_name', align: 'start', sortable: true },
        { title: 'Coverage', value: 'coverage.name', align: 'start', sortable: true },
        { title: 'Role', value: 'role.name', align: 'start' , sortable: true},
        { title: 'Status', key: 'status', align: 'start', sortable: true },
        { title: '', key: 'actions', align: 'end', sortable: false },
      ],
      users: [],
      tableActions: {
        edit: this.navigateToForm,
        delete: this.deleteUser,
        view: this.navigateToForm
      },
      loading: false
    };
  },
  computed: {
    breadcrumbItems() {
      const route = this.$route.name;
      const action = this.$route.params.action;
      const breadcrumbItems = breadcrumbConfig[route];

      // Handle case where the route is dynamic, e.g., 'user-form'
      if (typeof breadcrumbItems === 'function') {
        return breadcrumbItems(action);
      }

      return breadcrumbItems || [];
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async fetchUsers() {
      this.loading = true;
      try {
        const response = await axios.get('/users');
        this.users = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    navigateToForm(action, user = { id: null }) {
      if (action == 'view') {
        this.$router.push({ name: 'user-view', params: { action, id: user.id } });
      } else {
        this.$router.push({ name: 'user-form', params: { action, id: user.id } });
      }
    },
    async deleteUser(userToDelete) {
      try {
        const response = await axios.delete(`/users/${userToDelete.id}`);

        if (response.data) {
          this.users = this.users.filter(u => u.id !== userToDelete.id);

          this.showAlert({ message: 'Success', color: 'success' });
        } else {
          throw new Error('Failed to delete user');
        }
      } catch (error) {
        const errorMessage = error.response?.data?.messages 
                            ? error.response.data.messages 
                            : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchFilteredItems(filter) {
        this.loading = true;
        if (filter.attribute) {
          try {
            const response = await axios.get(`/users?status=${filter.attribute}`);
            this.users = response.data;
            this.loading = false;
          } catch (error) {
            this.loading = false;
            const errorMessage = error.response?.data?.messages 
                                ? error.response.data.messages 
                                : error.response?.message || 'An error occurred';
    
            this.showAlert({ message: errorMessage, color: 'error' });
          }
        } else {
          this.fetchUsers();
        }
        this.loading = false;
      }
  },
  mounted() {
    this.fetchUsers();
  }
};
</script>
