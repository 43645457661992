<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card elevation="0" class="mt-2">
    <template v-slot:append>
      <v-btn class="text-none" color="primary" @click="goToCustomizePage('edit', role)">
        Customize
      </v-btn>
    </template>

    <v-divider></v-divider>

    <v-card-text>
      <!-- Role Name -->
      <v-row>
        <v-col lg="2" md="2" sm="12" cols="12">
          <label class="custom-label">Role Name</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ role.name }}</div>
        </v-col>
      </v-row>

      <!-- Status -->
      <v-row>
        <v-col lg="2" md="2" sm="12" cols="12">
          <label class="custom-label">Status</label>
        </v-col>
        <v-col md="6" sm="6" cols="12">
          <div>{{ role.status }}</div>
        </v-col>
      </v-row>

      <!-- Modules and Permissions -->
      <v-divider class="my-4"></v-divider>
      <v-row>
        <v-col sm="12" cols="12">
          <label class="custom-label">Modules & Permissions</label>
        </v-col>
        <v-col cols="12">
          <v-data-table :headers="headers" :items="formattedModules" hide-default-footer>
            <template v-slot:item.permissions="{ item }">
              <div class="permission-wrapper">
                <v-checkbox v-for="permission in item.permissions" :key="permission" :label="permission"
                  v-model="checkedPermissions[item.name][permission]" disabled></v-checkbox>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';
import { mapActions } from 'vuex';

export default {
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      role: {
        id: null,
        name: '',
        status: '',
        permissions: [],
      },
      headers: [
        { title: 'Module', key: 'name' },
        { title: 'Permissions', key: 'permissions' },
      ],
      checkedPermissions: {}, // To store the checked state of permissions
    };
  },
  computed: {
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    },
    formattedModules() {
      const modulesMap = {};

      this.role.permissions.forEach(permission => {
        const [api, module, action] = permission.split('/');
        const formattedModule = module.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());

        if (!modulesMap[formattedModule]) {
          modulesMap[formattedModule] = { name: formattedModule, permissions: [] };
        }

        modulesMap[formattedModule].permissions.push(action);

        // Initialize checked state for each permission
        if (!this.checkedPermissions[formattedModule]) {
          this.checkedPermissions[formattedModule] = {};
        }
        this.checkedPermissions[formattedModule][action] = true;
      });

      return Object.values(modulesMap);
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    // Fetch role details
    if (id) {
      try {
        const response = await axios.get(`/role/${id}`);
        this.role = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    goToCustomizePage(action, role) {
      this.$router.push({ name: 'role-form', params: { action, id: role.id } });
    },
  },
};
</script>

<style scoped>
.custom-label {
  font-weight: bold;
  font-size: 11px;
}

.permission-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.permission-wrapper .v-checkbox {
  margin-right: 10px;
}
</style>