<template>
    <Breadcrumbs :items="breadcrumbItems" />
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn color="primary" @click="navigateToForm('add')">Add Event</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <data-table :headers="headers" :items="calendar" :actions="tableActions" :loading="loading" />
      </v-col>
    </v-row>
  </template>
  
  <script>
  import axios from '../plugins/axios';
  import Breadcrumbs from '@/components/Breadcrumbs.vue';
  import breadcrumbConfig from '@/others/breadcrumbConfig';
  import { mapActions } from 'vuex';
  import DataTable from '@/components/DataTable.vue';
  
  export default {
    components: {
      Breadcrumbs,
      DataTable
    },
    data() {
      return {
        headers: [
          { title: 'Title', key: 'title', align: 'start', sortable: true},
          { title: 'Period', value: 'period.title', align: 'start', sortable: true},
          { title: 'Working Days', key: 'working_days', align: 'start', sortable: true},
          { title: 'Start Date', key: 'start_date', align: 'start', sortable: true},
          { title: 'End Date', key: 'end_date', align: 'start', sortable: true},
          { title: '', key: 'actions', align: 'end', sortable: false },
        ],
        calendar: [],
        tableActions: {
          edit: this.navigateToForm,
          delete: this.deleteCalendar,
        },
        loading: false
      };
    },
    computed: {
      breadcrumbItems() {
        const route = this.$route.name;
        const action = this.$route.params.action;
        const breadcrumbItems = breadcrumbConfig[route];
  
        // Handle case where the route is dynamic, e.g., 'UOM-form'
        if (typeof breadcrumbItems === 'function') {
          return breadcrumbItems(action);
        }
  
        return breadcrumbItems || [];
      }
    },
    methods: {
      ...mapActions(['showAlert']),
      async fetchCalendar() {
        this.loading = true;
        try {
          const response = await axios.get('/calendar_manager');
          this.calendar = response.data;
          this.loading = false;
        } catch (error) {
          this.loading = false;
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      },
      navigateToForm(action, calendar = { id: null }) {
        if (action == 'view') {
          this.$router.push({ name: 'calendar-view', params: { action, id: calendar.id } });
        } else {
          this.$router.push({ name: 'calendar-form', params: { action, id: calendar.id } });
        }
      },
      async deleteCalendar(calendarToDelete) {
        try {
          const response = await axios.delete(`/calendar_manager/${calendarToDelete.id}`);
  
          if (response.data) {
            this.calendar = this.calendar.filter(u => u.id !== calendarToDelete.id);
  
            this.showAlert({ message: 'Success', color: 'success' });
          } else {
            throw new Error('Failed to delete calendar');
          }
        } catch (error) {
          const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'An error occurred';
  
          this.showAlert({ message: errorMessage, color: 'error' });
        }
      }
    },
    mounted() {
      this.fetchCalendar();
    }
  };
  </script>