<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
    <template v-slot:title> </template>

    <v-divider></v-divider>

    <v-card-text>
      <div>
        <v-form ref="form" @submit.prevent="handleSubmit">
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Transaction Number Prefix</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="rolling_forecast_config.transaction_number_prefix" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">District Identifier</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="rolling_forecast_config.district_identifier" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Area Identifier</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="rolling_forecast_config.area_identifier" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Region Identifier</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="rolling_forecast_config.region_identifier" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Domestic Identifier</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="rolling_forecast_config.domestic_identifier" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Enable Date Identifier</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-switch
                v-model="rolling_forecast_config.enable_date_identifier"
                color="primary"
                hide-details
                inset
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Date Format</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-select v-model="rolling_forecast_config.date_format"
              :items="dateFormats"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col lg="2" md="2" sm="2" cols="12">
              <label class="custom-label">Sequence Length</label>
            </v-col>
            <v-col md="6" sm="6" cols="12">
              <v-text-field v-model="rolling_forecast_config.sequence_length" required bgColor="none"></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>

    <template v-slot:append>
      <v-btn @click="submitForm" color="primary">
        {{ $t('button.save') }}
      </v-btn>
    </template>
  </v-card>

</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
    Breadcrumbs
  },
  data() {
    return {
      rolling_forecast_config: {
        id: null,
        transaction_number_prefix: '',
        date_format: '',
        sequence_length: '',
        district_identifier: '',
        area_identifier: '',
        region_identifier: '',
        domestic_identifier: '',
        enable_date_identifier: false
      },
      dateFormats: ['mmYYYY', 'ddMMYYYY', 'yyyyMMdd'],
      // coverageIdentifiers: ['District', 'Area', 'Region', 'Domestic'],
        // { coverage_type: 'District', identifier: 'DIST'},
        // { coverage_type: 'Area', identifier: 'AREA'},
        // { coverage_type: 'Region', identifier: 'REG'},
        // { coverage_type: 'Domestic', identifier: 'DOM'}
      // ],
      coverages: [],
      roles: [],
      selectedRoleId: null,
      selectedCoverageId: null,
      model: false
   
    };
  },
  computed: {
    isAdding() {
      return this.$route.params.action === 'add';
    },
    breadcrumbItems() {
      const action = this.$route.params.action;
      const route = this.$route.name;
      const items = breadcrumbConfig[route];

      if (typeof items === 'function') {
        return items(action);
      }

      return items || [];
    }
  },
  async mounted() {
    // Fetch Default Configuration
    try {
      const response = await axios.get(`/rolling_forecast_configuration`);
      if (response.data) {
        this.rolling_forecast_config = response.data;
      }
    } catch (error) {
      const errorMessage = error.response?.data?.messages
        ? error.response.data.messages
        : error.response?.message || 'An error occurred';
      this.showAlert({ message: errorMessage, color: 'error' });
    }
  },
  methods: {
    ...mapActions(['showAlert']),
    async handleSubmit() {
      try {
        const response = await axios.put(`/rolling_forecast_configuration`, { rofo_config: this.rolling_forecast_config });
        this.showAlert({ message: 'Config successfully update', color: 'success' });
        if (response.data) {
          this.$router.push({ name: 'rollingForecastConfiguration' });
        } else {
          throw new Error('Failed to update configuration');
        }

        console.log(this.rolling_forecast_config)
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    submitForm() {
      this.$refs.form.validate(); // Trigger form validation
      this.handleSubmit(); // Call the submit method
    },
    itemProps (item) {
        return {
          title: item.name,
          subtitle: `Type : ${item.coverage_type}, Parent: ${item.parent.name || 'None'}`,
        }
      },
  }
};
</script>
