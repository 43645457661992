<template>
  <Breadcrumbs :items="breadcrumbItems" />
  <v-card>
      <template v-slot:title>
        <v-row class="py-3" v-if="cmo.is_combined_document">
          <v-tabs v-model="selectedHeadTab" bg-color="white" show-arrows
            @update:modelValue="(value) => updateHeadTabValue(value, headTabsData.findIndex(tab => tab === value))">
            <v-tab v-for="(data, index) in headTabsData" :key="data" :text="data.ship_to"
              :value="data"></v-tab>
          </v-tabs>
        </v-row>

        <div>
          <v-chip class="ma-2" label>
            <v-icon icon="mdi-label" start></v-icon>
            {{ cmo.status || '' }}
          </v-chip>
        </div>
      </template>

      <v-divider></v-divider>

      <v-card-text>
          <div>
              <v-form ref="form" @submit.prevent="handleSubmit">
                  <v-row>
                      <v-col cols="3" md="3">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Transaction Type</label>
                              </v-col>
                              <v-col>
                                  <v-select readonly v-model="cmo.transaction_type_id"
                                  :items="transactionTypes" item-value="id" item-title="name"></v-select>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1" v-if="!cmo.is_combined_document">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">PO Distributor</label>
                              </v-col>
                              <v-col>
                                  <v-text-field v-model="cmo.purchase_order_number" bgColor="none"></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Transaction Number</label>
                              </v-col>
                              <v-col>
                                  <v-text-field readonly placeholder="Automatic" v-model="cmo.transaction_number"></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="3" md="3">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Shipment Type</label>
                              </v-col>
                              <v-col>
                                  <v-select :readonly="mode === 0 && selectedHeadTab !== 0" v-model="cmo.shipment_type"
                                  :items="shipmentTypes"></v-select>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1" v-if="!cmo.is_combined_document">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">PO Date</label>
                              </v-col>
                              <v-col>
                                  <v-date-input v-model="cmo.purchase_order_date" variant="outlined" density="compact"
                                      bgColor="none" :multiple="false" prepend-icon="" hide-details
                                      prepend-inner-icon="$calendar"></v-date-input>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Transaction Date</label>
                              </v-col>
                              <v-col>
                                  <v-text-field v-model="cmo.transaction_date"></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="3" md="3">
                          <v-row v-if="!cmo.is_combined_document">
                              <v-col>
                                  <label class="custom-label">Distributor Name</label>
                              </v-col>
                              <v-col>
                                  <v-select readonly v-model="cmo.sold_to" :items="parentDistributors" return-object item-title="name"
                                      placeholder="" @update:modelValue="soldToChanged($event)"></v-select>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1" v-if="!cmo.is_combined_document">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">PO Expired Date</label>
                              </v-col>
                              <v-col>
                                  <v-date-input v-model="cmo.purchase_order_expired_date" variant="outlined" density="compact"
                                      bgColor="none" :multiple="false" prepend-icon="" hide-details
                                      prepend-inner-icon="$calendar"></v-date-input>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Transaction Period</label>
                              </v-col>
                              <v-col>
                                <v-select readonly v-model="cmo.period_id"
                                  :items="periods" item-value="id" item-title="title" ></v-select>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="3" md="3">
                          <v-row v-if="!cmo.is_combined_document">
                              <v-col>
                                  <label class="custom-label">Distributor Address</label>
                              </v-col>
                              <v-col>
                                  <v-textarea readonly variant="outlined" rows="1" v-model="cmo.sold_to_address" 
                                      bgColor="none"></v-textarea>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
                  <!-- <v-row class="my-0">
                      <v-col cols="3" md="3">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Valid From</label>
                              </v-col>
                              <v-col>
                                  <v-date-input v-model="startDate" variant="outlined" density="compact"
                                      bgColor="none" :multiple="false" prepend-icon=""
                                      prepend-inner-icon="$calendar"></v-date-input>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Valid To</label>
                              </v-col>
                              <v-col>
                                  <v-date-input v-model="startDate" variant="outlined" density="compact"
                                      bgColor="none" :multiple="false" prepend-icon=""
                                      prepend-inner-icon="$calendar"></v-date-input>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row> -->
                  <v-row>
                      <v-col cols="12">
                          <label class="custom-label font-weight-bold">Shipping Information</label>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="3" md="3">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Ship to Name</label>
                              </v-col>
                              <v-col>
                                <v-select v-model="cmo.ship_to" :items="distributors" return-object item-title="name" @update:modelValue="shipToChanged($event)"
                                placeholder=""></v-select>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1" v-if="!cmo.is_combined_document">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Area</label>
                              </v-col>
                              <v-col>
                                  <v-text-field readonly v-model="cmo.ship_to_area" ></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="3" md="3">
                          <v-row v-if="!cmo.is_combined_document">
                              <v-col>
                                  <label class="custom-label">Ship to Address</label>
                              </v-col>
                              <v-col>
                                  <v-text-field v-model="cmo.ship_to_address"  bgColor="none"></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                      <v-col cols="3" md="3" offset="1" v-if="!cmo.is_combined_document">
                          <v-row>
                              <v-col>
                                  <label class="custom-label">Region</label>
                              </v-col>
                              <v-col>
                                  <v-text-field readonly v-model="cmo.ship_to_region" ></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
                  <v-row>
                      <v-col cols="3" md="3">
                          <v-row v-if="!cmo.is_combined_document">
                              <v-col>
                                  <label class="custom-label">Ship to City</label>
                              </v-col>
                              <v-col>
                                  <v-text-field v-model="cmo.ship_to_city"  bgColor="none"></v-text-field>
                              </v-col>
                          </v-row>
                      </v-col>
                  </v-row>
              </v-form>
          </div>
      </v-card-text>

      <template v-slot:append>
        <v-btn color="primary" class="ml-2" @click="submitForm(2)" :disabled="!asApprover || approvedDocument">Approve</v-btn>
        <v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" :disabled="!isOwner || cmo.status === 'Awaiting for Approval'">
          {{ $route.params.action === 'add' ? $t('button.save') : $t('button.update') }}

          <v-menu activator="parent" density="compact">
            <v-list density="compact">
              <v-list-item density="compact" title="Save as draft" value="edit" @click="submitForm(0)"></v-list-item>
              <v-list-item density="compact" title="Save & Submit" value="submit" @click="submitForm(1)"></v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
        <v-btn color="primary" class="ml-2" @click="requestForCombineDialog = true" :disabled="!isOwner || cmo.status === 'Awaiting for Approval' || cmo.is_combined_document">Req. Combine</v-btn>
        <v-btn color="primary" class="ml-2" append-icon="mdi-chevron-down" :disabled="(!asApprover)">
				Options

				<v-menu activator="parent" density="compact">
					<v-list density="compact">
						<!-- <v-list-item density="compact" title="Edit" value="edit" @click="updateMode"></v-list-item> -->
						<v-list-item density="compact" title="Request for Revise" value="edit" @click="requestForRevise"></v-list-item>
					</v-list>
				</v-menu>
			</v-btn>
      </template>
  </v-card>

  <div class="pa-3"></div>
  <v-card>
      <template v-slot:title>

          <v-btn class="mx-1" color="primary" variant="outlined" prepend-icon="mdi-fullscreen"
              @click="isBottomSheetOpen = true">
              Full Screen
          </v-btn>

      </template>

      <v-divider></v-divider>

      <v-card-text>
          <v-data-table hide-default-footer fixed-header :headers="headers" :items="selected_confirm_monthly_order_details_attributes" item-key="name"
              class="frozen-columns-table" :items-per-page="100" min-height="45vh">

              <template v-slot:item.rolling_forecast_outstanding="{ item }">
                <span>
                  {{ item.rolling_forecast_outstanding < 0 
                    ? `(${Math.abs(item.rolling_forecast_outstanding)})` 
                    : item.rolling_forecast_outstanding }}
                </span>
              </template>
              
              <!-- Customize Header Req.Delivery Date-->
              <template v-slot:header.v1="{ header }">
                  <v-date-input v-model="cmo.w1_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar" 
                      :allowed-dates="(date) => dateFilter(date, cmo.w1_request_delivery_date)"></v-date-input>
              </template>

              <template v-slot:header.v2="{ header }">
                  <v-date-input v-model="cmo.w2_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"
                      :allowed-dates="(date) => dateFilter(date, cmo.w2_request_delivery_date)"></v-date-input>
              </template>

              <template v-slot:header.v3="{ header }">
                  <v-date-input v-model="cmo.w3_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"
                      :allowed-dates="(date) => dateFilter(date, cmo.w3_request_delivery_date)"></v-date-input>
              </template>

              <template v-slot:header.v4="{ header }">
                  <v-date-input v-model="cmo.w4_request_delivery_date" variant="outlined" density="compact" bgColor="none"
                      :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"
                      :allowed-dates="(date) => dateFilter(date, cmo.w4_request_delivery_date)"></v-date-input>
              </template>
              <!-- // -->

              <!-- Customize Header Valid from - Valid To-->
              <template v-slot:header.w1="{ header }">
                  <v-row class="py-4">
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w1_valid_from" variant="outlined" density="compact" bgColor="none"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w1_valid_to" variant="outlined" density="compact" bgColor="none"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                  </v-row>
                  
              </template>

              <template v-slot:header.w2="{ header }">
                   <v-row>
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w2_valid_from" variant="outlined" density="compact" bgColor="none" class="px-4"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w2_valid_to" variant="outlined" density="compact" bgColor="none"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                  </v-row>
              </template>

              <template v-slot:header.w3="{ header }">
                   <v-row>
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w3_valid_from" variant="outlined" density="compact" bgColor="none"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w3_valid_to" variant="outlined" density="compact" bgColor="none"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                  </v-row>
              </template>

              <template v-slot:header.w4="{ header }">
                  <v-row>
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w4_valid_from" variant="outlined" density="compact" bgColor="none"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                      <v-col cols="6">
                          <v-date-input v-model="cmo.w4_valid_to" variant="outlined" density="compact" bgColor="none"
                               :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                      </v-col>
                  </v-row>
              </template>
              <!-- // -->

              <template v-slot:item.week1="{ item }">
                  <vue-number v-model="item.w1_qty" v-bind="number" @change="updateValue(item, 'grant_total_qty', 1)" class="custom-input text-right ma-2"></vue-number>
              </template>

              <template v-slot:item.week2="{ item }">
                  <input type="text" v-model="item.w2_qty" @change="updateValue(item, 'grant_total_qty', 2)" class="custom-input" />
              </template>

              <!-- Define slot for week3 -->
              <template v-slot:item.week3="{ item }">
                  <input type="text" v-model="item.w3_qty" @change="updateValue(item, 'grant_total_qty', 3)" class="custom-input" />
              </template>

              <!-- Define slot for week4 -->
              <template v-slot:item.week4="{ item }">
                  <input type="text" v-model="item.w4_qty" @change="updateValue(item, 'grant_total_qty', 4)" class="custom-input" />
              </template>

              <!-- Define slot for remarks -->
              <template v-slot:item.remarks="{ item }">
                  <input type="text" v-model="item.remarks" @change="updateValue(item, 'grant_total_qty')" class="custom-input" />
              </template>

              <!-- Append slot for the "Total" row -->
              <template v-slot:body.append>
                  <!-- Grand Total Order Qty -->
                  <tr class="elevation-0">
                      <td></td>
                      <td></td>
                      <td style="left: 0;font-weight: bold;width: 300px;">Grand Total Order Qty</td>
                      <td class="text-center"></td>
                      <td class="text-center"></td>
                      <!-- Total for qty_m0 -->
                      <td class="text-center"></td>
                      <!-- Total for qty_m1 -->
                      <td class="text-center">{{ grandTotalQty }}</td>
                      <td class="text-center">{{ w1TotalQty }}</td>
                      <td class="text-center">{{ w2TotalQty }}</td>
                      <td class="text-center">{{ w3TotalQty }}</td>
                      <td class="text-center">{{  w4TotalQty }}</td>
                      <td class="text-center"></td>
                  </tr>

                  <!-- Total Weight (Kg) -->
                  <tr class="elevation-0">
                      <td></td>
                      <td></td>
                      <td style="left: 0;font-weight: bold">Total Weight (Kg)</td>
                      <td class="text-center"></td>
                      <td class="text-center"></td>
                      <!-- Total for qty_m0 -->
                      <td class="text-center"></td>
                      <!-- Total for qty_m1 -->
                      <td class="text-center">{{  totalWeight.toFixed(2) }}</td>
                      <td class="text-center">{{ w1TotalWeight.toFixed(2) }}</td>
                      <td class="text-center">{{ w2TotalWeight.toFixed(2) }}</td>
                      <td class="text-center">{{ w3TotalWeight.toFixed(2) }}</td>
                      <td class="text-center">{{  w4TotalWeight.toFixed(2) }}</td>
                      <td class="text-center"></td>
                  </tr>

                  <!-- Total Volume CBM -->
                  <tr class="elevation-0">
                      <td></td>
                      <td></td>
                      <td style="left: 0;font-weight: bold">Total Volum (CBM)</td>
                      <td class="text-center"></td>
                      <td class="text-center"></td>
                      <!-- Total for qty_m0 -->
                      <td class="text-center"></td>
                      <!-- Total for qty_m1 -->
                      <td class="text-center">{{  totalVolume.toFixed(2) }}</td>
                      <td class="text-center">{{ w1TotalVolume.toFixed(2) }}</td>
                      <td class="text-center">{{ w2TotalVolume.toFixed(2) }}</td>
                      <td class="text-center">{{ w3TotalVolume.toFixed(2) }}</td>
                      <td class="text-center">{{  w4TotalVolume.toFixed(2) }}</td>
                      <td class="text-center"></td>
                  </tr>
              </template>
          </v-data-table>
      </v-card-text>

      <v-card-action v-if="!directTransaction">
          <div class="mx-5">
              <v-row>
                  <v-col cols="12" md>
                      <label class="custom-label font-weight-bold">Vehicle Recommendation</label>
                  </v-col>
              </v-row>
              <v-row align="start" justify="space-around">
                  <v-col cols="12" md="3" v-for="(week, index) in weeks" :key="index">
                      <v-card variant="outlined" class="my-5 mx-0 px-0">
                          <v-card-title class="text-center">
                              <label class="text-caption font-weight-bold">{{ week.week }}</label>
                          </v-card-title>
                          <v-divider></v-divider>
                              <v-card-text>
                                  <v-row>
                                      <v-col cols="12">
                                          <v-row class="mx-1 my-1">
                                              <v-col cols="8">
                                                <v-select
                                                  v-model="week.vehicle_id"
                                                  :items="vehicleTypes"
                                                  item-value="vehicle_id.id"  
                                                  item-title="vehicle_id.vehicle_name" 
                                                  label="Vehicle Type - Qty"
                                                  @update:modelValue="calculateVehicleQty(index+1)"
                                                ></v-select>
                                              </v-col>
                                              <v-col cols="4">
                                                  <v-text-field readonly v-model="week.total_unit" label="Qty"
                                                      min="1"></v-text-field>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Dimension/ Capacity :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">
                                                    {{ (vehicleDetail(week.vehicle_id, 'dimension') + ' CBM' || '') + '/' + (vehicleDetail(week.vehicle_id, 'capacity') + ' Kg' || '') }}
                                                  </label>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment (CBM) :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">{{ (week.fulfillment_cbm * 100).toFixed(2) + ' %' }}</label>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment (Kg) :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">{{ (week.fulfillment_kg * 100).toFixed(2) + ' %' }}</label>
                                              </v-col>
                                          </v-row>
                                      </v-col>
                                  </v-row>
                                  <v-row class="pt-4">
                                      <v-divider></v-divider>
                                  </v-row>
                                  <v-row>
                                      <v-col cols="12">    
                                          <v-row class="mx-2 my-0" no-gutters justify="end"> 
                                              <v-col class="d-flex justify-content-end">
                                                  <v-switch
                                                      v-model="week.sameType"
                                                      label="Same Type"
                                                      hide-details
                                                      density="comfortable"
                                                  ></v-switch>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1 my-1">
                                              <v-col cols="8">
                                                <v-select
                                                  v-model="week.additional_vehicle_id"
                                                  :items="vehicleTypes"
                                                  item-value="vehicle_id.id"  
                                                  item-title="vehicle_id.vehicle_name" 
                                                  label="Vehicle Type - Qty"
                                                  @update:modelValue="calculateVehicleQty(index+1)"
                                                ></v-select>
                                              </v-col>
                                              <v-col cols="4">
                                                  <v-text-field readonly v-model="week.additional_total_unit" label="Qty"
                                                      min="1"></v-text-field>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Dimension/ Capacity :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">
                                                    {{ (vehicleDetail(week.additional_vehicle_id, 'dimension') + ' CBM' || '') + '/' + (vehicleDetail(week.additional_vehicle_id, 'capacity') + ' Kg' || '') }}
                                                  </label>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment (CBM) :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">{{ (week.additional_fulfillment_cbm * 100).toFixed(2) + ' %' }}</label>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Fulfillment (Kg) :</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">{{ (week.additional_fulfillment_kg * 100).toFixed(2) + ' %' }}</label>
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-btn variant="text" color="primary"
                                                  @click="viewRecommendation(cmo)">
                                                  Add Qty Recommendation
                                              </v-btn>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">X-Small</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">
                                                   {{ week.xsmall_recomendation }}
                                                  </label>
                                              </v-col>
                                              <v-col>
                                                  Carton
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Small</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">
                                                   {{ week.small_recomendation }}
                                                  </label>
                                              </v-col>
                                              <v-col>
                                                  Carton
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Medium</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">
                                                   {{ week.medium_recomendation }}
                                                  </label>
                                              </v-col>
                                              <v-col>
                                                  Carton
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">Large</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">
                                                      {{ week.large_recomendation }}
                                                  </label>
                                              </v-col>
                                              <v-col>
                                                  Carton
                                              </v-col>
                                          </v-row>
                                          <v-row class="mx-1">
                                              <v-col>
                                                  <label class="custom-label">X-Large</label>
                                              </v-col>
                                              <v-col>
                                                  <label class="custom-label">
                                                      {{ week.xlarge_recomendation }}
                                                  </label>
                                              </v-col>
                                              <v-col>
                                                  Carton
                                              </v-col>
                                          </v-row>
                                      </v-col>
                                  </v-row>
                              </v-card-text>
                              <v-divider class="mt-2"></v-divider>
                      </v-card>
                  </v-col>
              </v-row>
          </div>

      </v-card-action>
  </v-card>

  <v-row class="text-caption mt-2">
      <!-- <v-col>History and Notes</v-col> -->
      <v-col>
          <v-btn-toggle v-model="toggle" color="primary" variant="text">
              <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-history">History</v-btn>
              <v-btn height="5vh" size="x-small" density="compact" prepend-icon="mdi-note">Notes</v-btn>
          </v-btn-toggle>
      </v-col>
  </v-row>
  <v-row class="mt-0 px-4" v-if="toggle === 0">
      <v-timeline align="start" side="end">
          <v-timeline-item dot-color="white" size="x-small" density="compact">
              <div class="d-flex">
                  <div class="me-4 text-caption">5pm</div>
                  <div>
                      <div class="text-caption">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia, eaque.
                      </div>
                  </div>
              </div>
          </v-timeline-item>

          <v-timeline-item dot-color="white" size="x-small" density="compact">
              <div class="d-flex">
                  <div class="me-4 text-caption">5pm</div>
                  <div>
                      <div class="text-caption">Lorem elit. Quia, eaque.
                      </div>
                  </div>
              </div>
          </v-timeline-item>


          <v-timeline-item dot-color="white" size="x-small" density="compact">
              <div class="d-flex">
                  <div class="me-4 text-caption">5pm</div>
                  <div>
                      <div class="text-caption">Lorem ipsum dolor sit amet, Quia, eaque.
                      </div>
                  </div>
              </div>
          </v-timeline-item>
      </v-timeline>
  </v-row>
  <v-row class="mt-0 px-4" v-if="toggle === 1">
      <v-timeline align="start" side="end">
          <v-timeline-item dot-color="white" size="x-small" density="compact">
              <QuillEditor ref="myEditor" theme="snow" toolbar="essential" v-model="notes" />
              <v-btn class="my-2">Post</v-btn>
          </v-timeline-item>

          <v-timeline-item dot-color="white" size="x-small" density="compact">
              <div class="d-flex">
                  <div class="me-4 text-caption">5pm</div>
                  <div>
                      <div class="text-caption">Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae,
                          aliquid unde, voluptate a id ea explicabo, aperiam nostrum debitis quaerat fugit. Quaerat
                          praesentium rem autem.
                      </div>
                  </div>
              </div>
          </v-timeline-item>


          <v-timeline-item dot-color="white" size="x-small" density="compact">
              <div class="d-flex">
                  <div class="me-4 text-caption">5pm</div>
                  <div>
                      <div class="text-caption">Lorem ipsum dolor sit amet, Quia, eaque.
                      </div>
                  </div>
              </div>
          </v-timeline-item>
      </v-timeline>
  </v-row>

  <v-dialog v-model="reviseRemarkDialog" max-width="500">
		<template v-slot:default="{ isActive }">
			<v-card rounded="lg">
				<v-card-title class="d-flex justify-space-between align-center">
					<div class="text-medium-emphasis ps-2">
						Revise Remarks
					</div>

					<v-btn
						icon="mdi-close"
						variant="text"
						@click="isActive.value = false"
					></v-btn>
				</v-card-title>

				<v-divider class="mb-4"></v-divider>

				<v-card-text>
					<div class="mb-2">Message</div>
					<v-textarea
						:counter="300"
						class="mb-2"
						rows="2"
						variant="outlined"
						persistent-counter
						v-model="cmo.revise_remark"
					></v-textarea>

					<div class="text-red mb-1">
						Caution: This action cannot be undone after you click the Save button.
          </div>
				</v-card-text>

				<v-divider class="mt-2"></v-divider>

				<v-card-actions class="my-2 d-flex justify-end">
					<v-btn
						class="text-none"
						text="Cancel"
						@click="isActive.value = false"
					></v-btn>

					<v-btn
						class="text-none"
						color="primary"
						text="Save & Revise"
						variant="flat"
						@click="handleSubmit(4)"
					></v-btn>
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>

  <v-dialog v-model="requestForCombineDialog" max-width="500">
		<template v-slot:default="{ isActive }">
			<v-card rounded="lg">
				<v-card-title class="d-flex justify-space-between align-center">
					<div class="text-medium-emphasis ps-2">
						Request Combine
					</div>

					<v-btn
						icon="mdi-close"
						variant="text"
						@click="isActive.value = false"
					></v-btn>
				</v-card-title>

				<v-divider class="mb-4"></v-divider>

				<v-card-text>
          <div class="text-black mb-1">
            Are you sure you want to request a combination? 
          </div>
          <div class="text-red mb-1">
            Caution: This action cannot be undone once you click the Save button.
          </div>
        </v-card-text>

				<v-divider class="mt-2"></v-divider>

				<v-card-actions class="my-2 d-flex justify-end">
					<v-btn
						class="text-none"
						text="Cancel"
						@click="isActive.value = false"
					></v-btn>

					<v-btn
						class="text-none"
						color="primary"
						text="Yes"
						variant="flat"
						@click="handleSubmit(3)"
					></v-btn>
				</v-card-actions>
			</v-card>
		</template>
	</v-dialog>


  <!-- Bottom Sheet for Full Screen Card -->
  <v-bottom-sheet v-model="isBottomSheetOpen" fullscreen>
      <v-card>
          <template v-slot:title>

              <v-btn class="mx-1" color="error" variant="outlined" prepend-icon="mdi-fullscreen"
                  @click="isBottomSheetOpen = false">
                  Exit Full Screen
              </v-btn>

          </template>

          <v-divider></v-divider>

          <v-card-text>
              <v-data-table hide-default-footer fixed-header :headers="headers" :items="items" item-key="name"
                  class="frozen-columns-table" :items-per-page="100" height="50vh">

                  <!-- Customize Header -->
                  <template v-slot:header.w1="{ header }">
                      <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                          :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                  </template>

                  <template v-slot:header.w2="{ header }">
                      <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                          :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                  </template>

                  <template v-slot:header.w3="{ header }">
                      <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                          :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                  </template>

                  <template v-slot:header.w4="{ header }">
                      <v-date-input v-model="startDate" variant="outlined" density="compact" bgColor="none"
                          :multiple="false" prepend-icon="" prepend-inner-icon="$calendar"></v-date-input>
                  </template>


                  <template v-slot:item.week1="{ item }">
                      <input type="text" v-model="item.week1" class="custom-input"  />
                  </template>

                  <template v-slot:item.week2="{ item }">
                      <input type="text" v-model="item.week2" class="custom-input" />
                  </template>

                  <!-- Define slot for week3 -->
                  <template v-slot:item.week3="{ item }">
                      <input type="text" v-model="item.week3" class="custom-input" />
                  </template>

                  <!-- Define slot for week4 -->
                  <template v-slot:item.week4="{ item }">
                      <input type="text" v-model="item.week4" class="custom-input" />
                  </template>

                  <!-- Define slot for remarks -->
                  <template v-slot:item.remarks="{ item }">
                      <input type="text" v-model="item.remarks" class="custom-input" />
                  </template>

                  <!-- Append slot for the "Total" row -->
                  <template v-slot:body.append>
                      <tr class="elevation-0">
                          <!-- Assuming you want a label for the total row -->
                          <td style="left: 0;">Total</td>
                          <!-- Total for Stock column -->
                          <td></td>
                          <td></td>
                          <td class="text-center">3333</td>
                          <td class="text-center">333</td>
                          <!-- Total for qty_m0 -->
                          <td class="text-center">333</td>
                          <!-- Total for qty_m1 -->
                          <td class="text-center">333</td>
                          <!-- Total for qty_m2 -->
                          <td class="text-center">12341234</td>
                          <td class="text-center">333</td>
                          <!-- Total for qty_m0 -->
                          <td class="text-center">12341234</td>
                          <!-- Total for qty_m1 -->
                          <td class="text-center">12341234</td>
                          <!-- Total for qty_m2 -->
                          <td class="text-center">12341234</td>
                      </tr>
                  </template>
              </v-data-table>
          </v-card-text>
          <v-card-action>
              <div class="mx-5">
                  <v-row>
                      <v-col cols="12" md>
                          <label class="custom-label font-weight-bold">Vehicle Recommendation</label>
                      </v-col>
                  </v-row>
                  <v-row align="start" justify="space-around">
                      <v-col cols="12" md="3" v-for="(week, index) in weeks" :key="index">
                          <v-card variant="outlined" class="my-5 mx-0 px-0">
                              <v-card-title class="text-center">
                                  <label class="text-caption font-weight-bold">{{ week.week }}</label>
                              </v-card-title>
                              <v-divider></v-divider>
                              <!-- <div v-for="(vehicle, vIndex) in week.vehicles" :key="vIndex"> -->
                                  <v-card-text>
                                      <v-row>
                                          <v-col cols="12">
                                              <v-row class="mx-2">
                                                  <v-col cols="1" offset="11">
                                                      <v-checkbox v-model="week.selected"></v-checkbox>
                                                  </v-col>
                                              </v-row>
                                              <v-row class="mx-1">
                                                  <v-col cols="8">
                                                    <v-select
                                                      v-model="week.vehicle_id"
                                                      :items="vehicleTypes"
                                                      item-value="vehicle_id.id"  
                                                      item-title="vehicle_id.vehicle_name" 
                                                      label="Vehicle Type - Qty"
                                                    ></v-select>
                                                  </v-col>
                                                  <v-col cols="4">
                                                      <v-text-field v-model="week.total_unit" label="Qty"
                                                          min="1"></v-text-field>
                                                  </v-col>
                                              </v-row>
                                              <v-row class="mx-1">
                                                  <v-col>
                                                      <label class="custom-label">Dimension/ Capacity :</label>
                                                  </v-col>
                                                  <v-col>
                                                      <label class="custom-label">{{ week.dimension }}</label>
                                                  </v-col>
                                              </v-row>
                                              <v-row class="mx-1">
                                                  <v-col>
                                                      <label class="custom-label">Fulfillment CBM :</label>
                                                  </v-col>
                                                  <v-col>
                                                      <label class="custom-label">{{ week.fulfillment }}</label>
                                                  </v-col>
                                              </v-row>
                                              <v-row class="mx-1">
                                                  <v-btn variant="text" color="primary"
                                                      @click="viewRecommendation(vehicle)">
                                                      View Qty Recommendation
                                                  </v-btn>
                                              </v-row>
                                          </v-col>
                                      </v-row>
                                  </v-card-text>
                                  <v-divider class="mt-2"></v-divider>
                              <!-- </div> -->
                          </v-card>
                      </v-col>
                  </v-row>
              </div>

          </v-card-action>
      </v-card>
  </v-bottom-sheet>

  <v-snackbar
    v-model="snackbar.show"
    outlined
    color="primary"
  >
    <div class="text-subtitle-1 pb-2">{{ snackbar.message }}</div>

    <!-- <p>This is a longer paragraph explaining something</p> -->

    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click="snackbar.show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>

</template>

<script>
import axios from '../plugins/axios';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { mapActions } from 'vuex';
import breadcrumbConfig from '@/others/breadcrumbConfig.js';

export default {
  components: {
      Breadcrumbs
  },
  data() {
      return {
          cmo: {
              id: null,
              period_id:null,
              sold_to: null,
              grand_total_qty: 0
          },
          cmoData: {},
          roles: [],
          selectedRoleId: null,
          tab: null,
          length: 3,
          headers: [
              {
                  title: 'REQ. DELIVERY DATE',
                  align: 'center',
                  children: [
                      {
                          title: 'Valid From - Valid To',
                          align: 'center',
                          value: 'valid-from-to',
                          children: [
                                { title: 'SKU', value: 'sku', align: 'left', clickable: true },
                                { title: 'Product Name', value: 'product_name', nowrap: true, align: 'left', width: '300px' },
                                { title: 'Product Nickname', value: 'alias_name', align: 'left' },
                                { title: 'UOM', value: 'uom.name', align: 'center' },
                                { title: 'ROFO Total', value: 'rolling_forecast_total', align: 'center' },
                                { title: 'ROFO Outstanding', value: 'rolling_forecast_outstanding', align: 'center' },
                                { title: 'Grand Total', value: 'grand_total_qty', align: 'center' },
                            ]
                      },
                  ],
                  
              },
              {
                  title: '',
                  align: 'center',
                  value: 'v1',
                  width: '600px',
                  children: [
                       {
                          title: '',
                          align: 'center',
                          value: 'w1',
                          width: '600px',
                          children: [
                              { title: 'Week 1', value: 'week1', align: 'center',width: '200px' },
                          ]
                      },
                  ]
              },
              {
                  title: '',
                  align: 'center',
                  value: 'v2',
                  children: [
                       {
                          title: '',
                          align: 'center',
                          value: 'w2',
                          children: [
                              { title: 'Week 2', value: 'week2', align: 'center' },
                          ]
                      },
                  ]
              },
              {
                  title: '',
                  align: 'center',
                  value: 'v3',
                  children: [
                       {
                          title: '',
                          align: 'center',
                          value: 'w3',
                          children: [
                              { title: 'Week 3', value: 'week3', align: 'center' },
                          ]
                      },
                  ]
              },
              {
                  title: '',
                  align: 'center',
                  value: 'v4',
                  children: [
                       {
                          title: '',
                          align: 'center',
                          value: 'w4',
                          children: [
                              { title: 'Week 4', value: 'week4', align: 'center' },
                          ]
                      },
                  ]
              },
              {
                  title: '',
                  align: 'center',
                  children: [
                       {
                          title: '',
                          align: 'center',
                          value: 'rmrk',
                          children: [
                              { title: 'Remarks', value: 'remarks', align: 'center' },
                          ]
                       }
                  ]
              },
              

          ],
          isBottomSheetOpen: false,
          toggle: null,
          notes: null,
          startDate: null,
          confirm_monthly_order_details_attributes: [
          ],
          vehicleTypes: [],
          weeks: [
              
          ],
          transactionTypes: [],
          shipmentTypes: [],
          periods: [],
          distributors: [],
          parentDistributors: [],
          selected_confirm_monthly_order_details_attributes: [],
          weekQtyKey: '',
          product_groups: [],
          snackbar: {
            show: false,
            message: '',
          },
          directTransaction: false,
          asApprover: false,
          approvedDocument: false,
          isOwner: false,
          reviseRemarkDialog: false,
          requestForCombineDialog: false,
          selectedHeadTab: 0,
          headTabsData: [],
          cmo_id: [],
      };
  },
  watch: {
      length(val) {
          this.tab = val - 1
      }
  },
  created() {
      // this.fetchTransactionTypes();
  },
  computed: {
      isAdding() {
          return this.$route.params.action === 'add';
      },
      breadcrumbItems() {
          const action = this.$route.params.action;
          const route = this.$route.name;
          const items = breadcrumbConfig[route];

          if (typeof items === 'function') {
              return items(action);
          }

          return items || [];
      },
      // soldToAddress() {
      //   return this.cmo.sold_to ? this.cmo.sold_to.address : '';
      // },
      // shipToAddress() {
      //   return this.cmo.ship_to ? this.cmo.ship_to.address : '';
      // },
      // shipToCity() {
      //   return this.cmo.ship_to ? this.cmo.ship_to.city : '';
      // },
      // shipToArea() {
      //   return this.cmo.ship_to ? this.cmo.ship_to.area : '';
      // },
      // shipToRegion() {
      //   return this.cmo.ship_to ? this.cmo.ship_to.region : '';
      // },
      grandTotalQty() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.grand_total_qty) || 0);
        }, 0);
      },
      totalWeight() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return (total + (Number(item.grand_total_qty * item.general_dimension_gross_weight)) / 1000 || 0);
        }, 0);
      },

      totalVolume() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          return total + (Number(item.grand_total_qty * item.ratio_loading_capacity) || 0);
        }, 0);
      },

      w1TotalQty() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w1_qty) || 0);
          }, 0);
        },
        w2TotalQty() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w2_qty) || 0);
          }, 0);
        },
        w3TotalQty() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w3_qty) || 0);
          }, 0);
        },
        w4TotalQty() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w4_qty) || 0);
          }, 0);
        },

         // Total Weight Footer
        w1TotalWeight() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return (total + (Number(item.w1_qty * item.general_dimension_gross_weight)) / 1000 || 0);
          }, 0);
        },
        w2TotalWeight() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return (total + (Number(item.w2_qty * item.general_dimension_gross_weight)) / 1000 || 0);
          }, 0);
        },
        w3TotalWeight() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return (total + (Number(item.w3_qty * item.general_dimension_gross_weight)) / 1000 || 0);
          }, 0);
        },
        w4TotalWeight() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return (total + (Number(item.w4_qty * item.general_dimension_gross_weight)) / 1000 || 0);
          }, 0);
        },


        // Total Volume Footer
        w1TotalVolume() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w1_qty * item.ratio_loading_capacity) || 0);
          }, 0);
        },
        w2TotalVolume() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w2_qty * item.ratio_loading_capacity) || 0);
          }, 0);
        },
        w3TotalVolume() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w3_qty * item.ratio_loading_capacity) || 0);
          }, 0);
        },
        w4TotalVolume() {
          return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
            return total + (Number(item.w4_qty * item.ratio_loading_capacity) || 0);
          }, 0);
        },

      // See example on XLS file CMO calculation
      weightConvertion() { //week_qty should be like w1_qty, so i can fetch item.w1_qty based on parameters
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          const weight = Number(item[this.weekQtyKey] * item.product_group_gross_weight) || 0;
          return total + weight;
        }, 0);
      },
      volumeConvertion() {
        return this.selected_confirm_monthly_order_details_attributes.reduce((total, item) => {
          const volume = Number(item[this.weekQtyKey] * item.ratio_loading_capacity) || 0;
          return total + volume;
        }, 0);
      }



      // simplifiedVehicleTypes() {
      //   return this.vehicleTypes.map(item => ({
      //     id: item.vehicle_id.id,
      //     name: item.vehicle_id.vehicle_name
      //   }));
      // }
      
  },
  async mounted() {
      const { action, id } = this.$route.params;
      this.cmo_id = id;
      // this.fetchDistributors();
      // this.fetchParentDistributor();
      // this.fetchProductGroup();

      if (!this.isAdding) {
        this.populateData(id);
      } else {
        // TODO
      }    
  },
  methods: {
    ...mapActions(['showAlert']),
    async changeCmoType(transaction_type_id) {
      this.loading = true;
      try {
        const response = await axios.get(`/confirm_monthly_order/new?type=${transaction_type_id}`);
        this.cmo = response.data;
        this.directTransaction = response.data.is_direct_transaction;
        this.periods = [...response.data.period];
        this.cmo.period_id = response.data.period[0].id;

        this.distributors = response.data.ship_to_list;
        this.cmo.ship_to = response.data.distributor;
        this.shipmentTypes = response.data.distributor.cmo_shipment_type;
        this.vehicleTypes = [...response.data.distributor.distributor_vehicles]; // Assign Vehicle list based on distributor selected
        this.cmo.ship_to_address = response.data.distributor.address;
        this.cmo.ship_to_city = response.data.distributor.city;
        this.cmo.ship_to_area = response.data.distributor.area;
        this.cmo.ship_to_region = response.data.distributor.region;

        this.cmo.sold_to = response.data.sold_to;
        this.cmo.sold_to_address = response.data.sold_to.address;
        this.confirm_monthly_order_details_attributes = [...response.data.confirm_monthly_order_details_attributes];
        
        // Filter to find data with matching distributor_id
        this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes
            .filter(detail => detail.distributor_id === response.data.distributor.id)
            .map(detail => detail.product_details)
            .flat();

        // Convert dates to JavaScript Date objects
        this.cmo.w1_request_delivery_date = new Date(response.data.w1_request_delivery_date);
        this.cmo.w2_request_delivery_date = new Date(response.data.w2_request_delivery_date);
        this.cmo.w3_request_delivery_date = new Date(response.data.w3_request_delivery_date);
        this.cmo.w4_request_delivery_date = new Date(response.data.w4_request_delivery_date);

        // Convert valid_from and valid_to to Date objects
        this.cmo.w1_valid_from = new Date(response.data.w1_valid_from);
        this.cmo.w1_valid_to = new Date(response.data.w1_valid_to);

        this.cmo.w2_valid_from = new Date(response.data.w2_valid_from);
        this.cmo.w2_valid_to = new Date(response.data.w2_valid_to);

        this.cmo.w3_valid_from = new Date(response.data.w3_valid_from);
        this.cmo.w3_valid_to = new Date(response.data.w3_valid_to);

        this.cmo.w4_valid_from = new Date(response.data.w4_valid_from);
        this.cmo.w4_valid_to = new Date(response.data.w4_valid_to);

      } catch (error) {
        const errorMessage = error.response?.data?.messages
            ? error.response.data.messages
            : error.response?.message || 'error di awal';

        this.showAlert({ message: errorMessage, color: 'error' });
        this.loading = false;
        this.$router.push({ name: 'cmo' });
      } 
      this.loading = false;
    },
    async populateData(id) {
      this.loading = true;
      if(id) {
        const response = await axios.get(`/confirm_monthly_order/${id}`);
        this.cmo = response.data;
        this.periods = [...response.data.period];
        this.cmo.period_id = response.data.period[0].id;
        this.cmo.transaction_type_id = response.data.transaction_type;
        this.distributors = response.data.ship_to_list;
        this.cmo.ship_to = response.data.distributor;
        this.shipmentTypes = response.data.distributor.cmo_shipment_type;
        this.vehicleTypes = [...response.data.distributor.distributor_vehicles]; // Assign Vehicle list based on distributor selected
        this.cmo.ship_to_address = response.data.distributor.address;
        this.cmo.ship_to_city = response.data.distributor.city;
        this.cmo.ship_to_area = response.data.distributor.area;
        this.cmo.ship_to_region = response.data.distributor.region;
        this.asApprover = response.data.is_approver;
        this.approvedDocument = response.data.status === "Approved" ? true : false;
        this.isOwner = response.data.is_owner;
        
        // Assign vehicle recomendations data
        this.weeks = response.data.confirm_monthly_order_vehicle_details_attributes;

        this.cmo.sold_to = response.data.sold_to;
        this.cmo.sold_to_address = response.data.sold_to.address;
        this.confirm_monthly_order_details_attributes = [...response.data.confirm_monthly_order_details_attributes];
        
        this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes

        // Convert dates to JavaScript Date objects
        this.cmo.w1_request_delivery_date = new Date(response.data.w1_request_delivery_date);
        this.cmo.w2_request_delivery_date = new Date(response.data.w2_request_delivery_date);
        this.cmo.w3_request_delivery_date = new Date(response.data.w3_request_delivery_date);
        this.cmo.w4_request_delivery_date = new Date(response.data.w4_request_delivery_date);

        // Convert valid_from and valid_to to Date objects
        this.cmo.w1_valid_from = new Date(response.data.w1_valid_from);
        this.cmo.w1_valid_to = new Date(response.data.w1_valid_to);

        this.cmo.w2_valid_from = new Date(response.data.w2_valid_from);
        this.cmo.w2_valid_to = new Date(response.data.w2_valid_to);

        this.cmo.w3_valid_from = new Date(response.data.w3_valid_from);
        this.cmo.w3_valid_to = new Date(response.data.w3_valid_to);

        this.cmo.w4_valid_from = new Date(response.data.w4_valid_from);
        this.cmo.w4_valid_to = new Date(response.data.w4_valid_to);

        this.directTransaction = response.data.is_direct_transaction;

        this.cmo.combined_cmo_ids = response.data.combined_cmo_ids;
        this.cmo.is_combined_document = response.data.is_combined_document;

        this.cmo.purchase_order_date = new Date(response.data.purchase_order_date);
        this.cmo.purchase_order_expired_date = new Date(response.data.purchase_order_expired_date);
        this.cmo.purchase_order_number = response.data.purchase_order_number;

        this.headTabsData = [...response.data.combined_cmo_ids];

        this.product_groups = response.data.product_groups;
        this.transactionTypes = response.data.transaction_types;
        
      } else {
        
      }
      
      this.loading = false;
    },
    requestForRevise() {
			this.reviseRemarkDialog = true;
		},
    requestForCombine() {

    },
    async handleSubmit(type) {
      const status = type === 0 ? 'Draft' : type === 1 ? 'Awaiting for Approval': type === 2 ? 'Approved' : type === 3 ? 'Awaiting Combining' : 'Request for Revise';
        try {
            const cmoParams = {
              id: this.cmo.id,
              transaction_date: this.cmo.transaction_date,
              period_id: this.cmo.period_id,
              transaction_type_id: this.cmo.transaction_type_id,
              shipment_type: this.cmo.shipment_type,
              distributor_id: this.cmo.ship_to.id,
              purchase_order_number: this.cmo.purchase_order_number,
              purchase_order_date: this.cmo.purchase_order_date,
              purchase_order_expired_date: this.cmo.purchase_order_expired_date,
              w1_valid_from: this.cmo.w1_valid_from,
              w1_valid_to: this.cmo.w1_valid_to,
              w2_valid_from: this.cmo.w2_valid_from,
              w2_valid_to: this.cmo.w2_valid_to,
              w3_valid_from: this.cmo.w3_valid_from,
              w3_valid_to: this.cmo.w3_valid_to,
              w4_valid_from: this.cmo.w4_valid_from,
              w4_valid_to: this.cmo.w4_valid_to,
              w1_request_delivery_date: this.cmo.w1_request_delivery_date,
              w2_request_delivery_date: this.cmo.w2_request_delivery_date,
              w3_request_delivery_date: this.cmo.w3_request_delivery_date,
              w4_request_delivery_date: this.cmo.w4_request_delivery_date,
              grand_total_order_qty: this.grandTotalQty,
              total_weight: this.totalWeight,
              total_volume: this.totalVolume,
              status: status,
              is_combined_document: this.cmo.is_combined_document,
              combined_cmo_ids: this.cmo.combined_cmo_ids,
              confirm_monthly_order_details_attributes: this.selected_confirm_monthly_order_details_attributes,
              confirm_monthly_order_vehicle_details_attributes: this.weeks
            };

            if (this.$route.params.action === 'add') {
              const response = await axios.put('/confirm_monthly_order', { confirm_monthly_order: cmoParams });

              this.showAlert({ message: 'CMO data successfully saved', color: 'success' });
              this.reviseRemarkDialog = true;
              if (response.data) {
                this.$router.push({ name: 'cmo' });
              } else {
                throw new Error(response.message);
              }
            } else {
              // const updateStatus = 'Awaiting for Approval';
              // const response = await axios.put(`/confirm_monthly_order/${this.cmo.id}`, { confirm_monthly_order: cmoParams });
              const updateStatus = type === 0 ? null : type === 1 ? 'submit' : type === 2 ? 'approve' : type === 3 ? 'req_combine' : 'revise';
              let response;
              if (updateStatus) {
                  response = await axios.put(`/confirm_monthly_order/${this.cmo.id}/${updateStatus}`, { confirm_monthly_order: cmoParams });
              } else {
                  response = await axios.put(`/confirm_monthly_order/${this.cmo.id}`, { confirm_monthly_order: cmoParams });
              }

              this.showAlert({ message: 'CMO data successfully update', color: 'success' });
              if (response.data) {
                this.$router.push({ name: 'cmo' });
              } else {
                throw new Error('Failed to update CMO');
              }
            }
        } catch (error) {
            const errorMessage = error.response?.data?.messages
                ? error.response.data.messages 
                : error.response?.message || 'An error occurred';

            this.showAlert({ message: errorMessage, color: 'error' });
        }
        this.requestForCombineDialog = false;
    },
    submitForm(type) {
        this.$refs.form.validate(); // Trigger form validation
        this.handleSubmit(type); // Call the submit method
    },
    toggleFullScreen() {
        this.isBottomSheetOpen = true;
    },
    viewRecommendation(vehicle) {
        // Handle viewing quantity recommendation logic here
        console.log('Viewing recommendation for:', vehicle);
    },
    // getSelectedVehicles() {
    //     const selectedVehicles = [];
    //     this.weeks.forEach(week => {
    //         week.vehicles.forEach(vehicle => {
    //             if (vehicle.selected) {
    //                 selectedVehicles.push(vehicle);
    //             }
    //         });
    //     });
    //     return selectedVehicles;
    // },
    async fetchTransactionTypes() {
        try {
            const response = await axios.get('/transaction_type');
            this.transactionTypes = response.data;
        } catch (error) {
            const errorMessage = error.response?.data?.messages
                ? error.response.data.messages
                : error.response?.message || 'An error occurred';

            this.showAlert({ message: errorMessage, color: 'error' });
        }
    },
    async fetchParentDistributor() {
      try {
        const response = await axios.get('/distributor?is_parent=true');
        this.parentDistributors = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchDistributors(parent_id) {
      try {
      //   const response = await axios.get(`/distributor?parent_id=${parent_id}`);
      const response = await axios.get(`/distributor`);
        this.distributors = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    async fetchProductGroup() {
      try {
      const response = await axios.get(`/product_group`);
        this.product_groups = response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.messages
          ? error.response.data.messages
          : error.response?.message || 'An error occurred';

        this.showAlert({ message: errorMessage, color: 'error' });
      }
    },
    soldToChanged(event) {
      if(event) {
        this.fetchDistributors(event.id)  
      } else {
        this.distributors = []
      }
    },
    shipToChanged(event) {
      console.log(event)
      this.cmo.ship_to_address = event.address;
      this.cmo.ship_to_city = event.city;
      this.cmo.ship_to_area = event.area;
      this.cmo.ship_to_region = event.region;
      this.shipmentTypes = event.cmo_shipment_type;
      this.cmo.shipment_type = null;

      this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes
            .filter(detail => detail.distributor_id === this.cmo.ship_to.id)
            .map(detail => detail.product_details)
            .flat();

      console.warn(this.selected_confirm_monthly_order_details_attributes);

    },
    dateFilter(date, requestDeliveryDate) {
      const currentDate = new Date(date);
      const startBlockedDate = new Date(requestDeliveryDate);
      const endBlockedDate = new Date(requestDeliveryDate);
      endBlockedDate.setDate(endBlockedDate.getDate() + 7); // Add 7 days

      // Allow only the dates between startBlockedDate and endBlockedDate
      return currentDate >= startBlockedDate && currentDate <= endBlockedDate;
    },
    updateValue(item, type, week=null) {
        switch (type) {
            case 'grant_total_qty':
                item.grand_total_qty = this.calculateGrandTotalQty(item.w1_qty, item.w2_qty, item.w3_qty, item.w4_qty);
                item.rolling_forecast_outstanding = this.calculateOutstading(item.grand_total_qty, item.rolling_forecast_total);
                if(!this.directTransaction) {
                  this.calculateVehicleQty(week);
                }
                break;
            case 'w2':
                
                break;
        }
    },
    productGroupGrossWeight(group) {
      if(this.product_groups.length > 0) {
        const product_group = this.product_groups.find(product_group => product_group.name === group);
        return product_group ? (product_group.gross_weight / 1000) : 0;
      }
    },
    productGroupRatioBasisBag(group){
      if(this.product_groups.length > 0) {
        const product_group = this.product_groups.find(product_group => product_group.name === group);
        return product_group ? (product_group.ratio_loading_capacity) : 0;
      }
    },
    calculateVehicleQty(wk = null) {
      this.weeks.forEach((week, index) => {
        if (wk !== null && wk === index + 1) {
          this.weekQtyKey = `w${wk}_qty`;

          const totalWeight = Math.ceil(this.weightConvertion);
          const totalVolume = Math.ceil(this.volumeConvertion);

          try {
            const vehicle = this.vehicleTypes.find(v => v.vehicle_id.id === week.vehicle_id);
            const additionalVehicle = this.vehicleTypes.find(v => v.vehicle_id.id === week.additional_vehicle_id);

            if (!vehicle) throw new Error("Vehicle not found");

            const vehicleWeight = vehicle.vehicle_id.weight;
            const additionalVehicleWeight = additionalVehicle?.vehicle_id.weight || 0;

            const vehicleLoadingCapacity = vehicle.vehicle_id.loading_ratio_in_crt;
            const additionalVehicleLoadingCapacity = additionalVehicle?.vehicle_id.loading_ratio_in_crt || 0;

            const combinedMaxCapacity = Math.max(
              totalVolume / vehicleLoadingCapacity,
              totalWeight / vehicleWeight,
              totalVolume / additionalVehicleLoadingCapacity,
              totalWeight / additionalVehicleWeight
            );

            const totalVehicleQty = combinedMaxCapacity > 1
              ? Math.trunc(combinedMaxCapacity)
              : combinedMaxCapacity.toFixed(2);
            const excessCapacity = combinedMaxCapacity.toFixed(2) - totalVehicleQty;

            week.total_unit = totalVehicleQty;
            week.additional_total_unit = excessCapacity.toFixed(2);

            if (combinedMaxCapacity < 1) {
              week.fulfillment_cbm = totalVolume > 0
                ? (totalVolume / vehicleLoadingCapacity).toFixed(2)
                : '0.00';
              week.fulfillment_kg = totalWeight > 0
                ? (totalWeight / vehicleWeight).toFixed(2)
                : '0.00';
            } else {
              week.fulfillment_cbm = totalVolume > 0
                ? (totalVolume / (combinedMaxCapacity * vehicleLoadingCapacity)).toFixed(2)
                : '0.00';
              week.fulfillment_kg = totalWeight > 0
                ? (totalWeight / (combinedMaxCapacity * vehicleWeight)).toFixed(2)
                : '0.00';
            }

            if (excessCapacity > 0 && additionalVehicle) {
              const rawFulfillmentCbm = totalVolume / vehicleLoadingCapacity;
              const rawFulfillmentKg = totalWeight / vehicleWeight;

              let excessCbm = rawFulfillmentCbm - Math.floor(rawFulfillmentCbm);
              let excessKg = rawFulfillmentKg - Math.floor(rawFulfillmentKg);

              // console.log('CBM', excessCbm)
              // console.log('KG', excessKg)

              week.fulfillment_cbm = rawFulfillmentCbm > 1 ? 1 : parseFloat(rawFulfillmentCbm);
              week.additional_fulfillment_cbm = rawFulfillmentCbm > 1
                ? (rawFulfillmentCbm - Math.trunc(rawFulfillmentCbm)).toFixed(2)
                : '0';

              week.fulfillment_kg = rawFulfillmentKg > 1 ? 1 : parseFloat(rawFulfillmentKg);
              week.additional_fulfillment_kg = rawFulfillmentKg > 1
                ? (rawFulfillmentKg - Math.trunc(rawFulfillmentKg)).toFixed(2)
                : '0';
            } else {
              week.additional_fulfillment_cbm = '0.00';
              week.additional_fulfillment_kg = '0.00';
            }

            const productRecommendations = this.calculateProductRecommendations(
              additionalVehicleWeight, additionalVehicleLoadingCapacity, excessCapacity
            );

            Object.assign(week, productRecommendations);
          } catch (error) {
            this.snackbar.show = true;
            this.snackbar.message = error.message;
          }
        }
      });
    },
    calculateProductRecommendations(additionalVehicleWeight, additionalVehicleLoadingCapacity, excessCapacity) {
      const xSmallWeight = this.productGroupGrossWeight('X-Small');
      const xSmallRatio = this.productGroupRatioBasisBag('X-Small');
      const smallWeight = this.productGroupGrossWeight('Small');
      const mediumWeight = this.productGroupGrossWeight('Medium');
      const mediumRatio = this.productGroupRatioBasisBag('Medium');
      const largeWeight = this.productGroupGrossWeight('Large');
      const largeRatio = this.productGroupRatioBasisBag('Large');
      const xLargeWeight = this.productGroupGrossWeight('X-Large');
      const xLargeRatio = this.productGroupRatioBasisBag('X-Large');

      const weightFraction = 1 - excessCapacity;

      const smallRecommendation = Math.ceil(Math.min(
        (weightFraction * additionalVehicleWeight) / smallWeight,
        additionalVehicleLoadingCapacity * weightFraction
      ));

      const mediumRecommendation = Math.ceil(Math.min(
        smallRecommendation / mediumRatio,
        (weightFraction * additionalVehicleWeight) / mediumWeight
      ));

      const xLargeRecommendation = Math.ceil(Math.min(
        smallRecommendation / xLargeRatio,
        (weightFraction * additionalVehicleWeight) / xLargeWeight
      ));

      const xSmallRecommendation = Math.ceil(smallRecommendation / xSmallRatio);
      const largeRecommendation = Math.ceil(smallRecommendation / largeRatio);

      return {
        xsmall_recomendation: xSmallRecommendation,
        small_recomendation: smallRecommendation,
        medium_recomendation: mediumRecommendation,
        large_recomendation: largeRecommendation,
        xlarge_recomendation: xLargeRecommendation
      };
    },
    calculateGrandTotalQty(w1_qty, w2_qty, w3_qty, w4_qty) {
      
      const q1 = w1_qty ? Number(w1_qty) : 0;
      const q2 = w2_qty ? Number(w2_qty) : 0;
      const q3 = w3_qty ? Number(w3_qty) : 0;
      const q4 = w4_qty ? Number(w4_qty) : 0;

      return q1 + q2 + q3 + q4;
    },
    calculateOutstading(grand_total_qty, rolling_forecast_total) {
      return Number(grand_total_qty) - Number(rolling_forecast_total);
    },
    vehicleDetail(vehicleId, type) {
      const vehicle = this.vehicleTypes.find(v => v.vehicle_id.id === vehicleId);
      if (!vehicle) return ''; 

      // Return the requested type or empty string if the property is undefined
      return type === 'dimension' ? (vehicle.vehicle_id.volume || '') : (vehicle.vehicle_id.weight || '');
    },
    async updateHeadTabValue(value, index) {
        if(index > 0) {
          this.loading = true;
          const response = await axios.get(`/confirm_monthly_order/${value.id}`);
          this.cmo = response.data;
          this.periods = [...response.data.period];
          this.cmo.period_id = response.data.period[0].id;
          this.cmo.transaction_type_id = response.data.transaction_type;
          this.distributors = response.data.ship_to_list;
          this.cmo.ship_to = response.data.distributor;
          this.shipmentTypes = response.data.distributor.cmo_shipment_type;
          this.vehicleTypes = [...response.data.distributor.distributor_vehicles]; // Assign Vehicle list based on distributor selected
          this.cmo.ship_to_address = response.data.distributor.address;
          this.cmo.ship_to_city = response.data.distributor.city;
          this.cmo.ship_to_area = response.data.distributor.area;
          this.cmo.ship_to_region = response.data.distributor.region;
          this.asApprover = response.data.is_approver;
          this.approvedDocument = response.data.status === "Approved" ? true : false;
          this.isOwner = response.data.is_owner;
          
          // Assign vehicle recomendations data
          this.weeks = response.data.confirm_monthly_order_vehicle_details_attributes;

          this.cmo.sold_to = response.data.sold_to;
          this.cmo.sold_to_address = response.data.sold_to.address;
          this.confirm_monthly_order_details_attributes = [...response.data.confirm_monthly_order_details_attributes];
          
          this.selected_confirm_monthly_order_details_attributes = this.confirm_monthly_order_details_attributes

          // Convert dates to JavaScript Date objects
          this.cmo.w1_request_delivery_date = new Date(response.data.w1_request_delivery_date);
          this.cmo.w2_request_delivery_date = new Date(response.data.w2_request_delivery_date);
          this.cmo.w3_request_delivery_date = new Date(response.data.w3_request_delivery_date);
          this.cmo.w4_request_delivery_date = new Date(response.data.w4_request_delivery_date);

          // Convert valid_from and valid_to to Date objects
          this.cmo.w1_valid_from = new Date(response.data.w1_valid_from);
          this.cmo.w1_valid_to = new Date(response.data.w1_valid_to);

          this.cmo.w2_valid_from = new Date(response.data.w2_valid_from);
          this.cmo.w2_valid_to = new Date(response.data.w2_valid_to);

          this.cmo.w3_valid_from = new Date(response.data.w3_valid_from);
          this.cmo.w3_valid_to = new Date(response.data.w3_valid_to);

          this.cmo.w4_valid_from = new Date(response.data.w4_valid_from);
          this.cmo.w4_valid_to = new Date(response.data.w4_valid_to);

          this.directTransaction = response.data.is_direct_transaction;

          // this.cmo.combined_cmo_ids = response.data.combined_cmo_ids;
          this.cmo.is_combined_document = true;
          this.cmo.combined_cmo_ids = response.data.combined_cmo_ids;
            
          this.loading = false;
        } else {
          this.selectedHeadTab = 0;
          this.populateData(this.cmo_id)
        }
      }

  }
};
</script>

<style scoped>
.v-col {
  padding: 5px;
}

.custom-input {
  border: 1px solid #ddd;
  padding: 6px 6px;
  border-radius: 2px;
  width: 11vw;
  box-sizing: border-box;
}

.custom-input:focus {
  border-color: #1976d2;
  outline: none;
}

/* Apply the same fixed positioning to the Total row's fixed columns */
.fixed-column {
  position: sticky;
  background-color: white;
  z-index: 1;
}

/* Apply specific width for each fixed column to match headers */
.total-label {
  width: 100px;
  /* Adjust this to match the width of the first column */
}

.fixed-column:nth-child(2) {
  left: 100px;
  /* Adjust based on the width of the first fixed column */
}

/* Ensure the sticky columns stay in place when scrolling */
tr>.fixed-column {
  box-shadow: 2px 0px 5px -2px rgba(0, 0, 0, 0.2);
}

:deep(.v-table.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th) {
    padding-bottom: 14px;
    color: black;
}
:deep(.v-table.v-table--fixed-header>.v-table__wrapper>table>thead>tr>th) {
    padding: 16px;
}

:deep(.v-table.v-table--fixed-header > .v-table__wrapper > table > thead > tr > th) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep(.v-table .v-table__wrapper > table > tbody > tr:not(:last-child) > td) {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

:deep(.v-table > .v-table__wrapper > table > tbody > tr > td) {
	border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}

.v-table {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity));
}
</style>